<template>
  <v-alert
      :value="true"
      :color="color"
      dark
      dismissible
      border="top"
      transition="scale-transition"
      @input="onAlertClosed"
  >
    {{ message }}
  </v-alert>
</template>

<script>
import {mapActions} from "vuex";

export default {
  props: {
    message: {
      type: String,
      required: true
    },
    color:{
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('ui', ['clearNotifications']),

    async onAlertClosed(visible) {
      if (!visible) {
        await this.clearNotifications()
      }
    },
  },
};
</script>