import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

const router = new VueRouter({
    mode: 'history',
    base: "/",
    routes: [


        {
            path: '/:md5promo',
            name: 'listapromotores',
            component: () => import("@/components/listapr/lst"),
            meta: {
                title: 'Link Promotor | Tunay',
                layout: 'CabSdaWithOutPieLayout'
            }
        },
        {
            path: '/lista/reserva/:codigo',
            name: 'listareserva',
            component: () => import("@/components/listapr/listareserva"),
            meta: {
                layout: 'CabSdaWithOutPieLayout'
            }
        },
        {
            path: '/l/:md5promo',
            name: 'listapromotores',
            component: () => import("@/components/listapr/promot"),
            meta: {
                layout: 'CabSdaWithOutPieLayout'
            }
        },
        {
            path: '/lista/email/:md5promo',
            name: 'listamail',
            component: () => import("@/components/listapr/listamail"),
            meta: {
                layout: 'CabSdaWithOutPieLayout'
            }
        },
        {
            path: '/listaqr/:md5promo',
            name: 'listaqr',
            component: () => import("@/components/listapr/lst"),
            meta: {
                layout: 'CabSdaWithOutPieLayout'
            }
        },
        {
            path: '/mislistas/:md5promo',
            name: 'mislistas',
            component: () => import("@/components/listapr/lst"),
            meta: {
                layout: 'CabSdaWithOutPieLayout'
            }
        },


        {
            path: '*',
            name: 'error',
            component: () => import("@/error/notfound")
        }
    ]
});
router.beforeEach((to, from, next) => {
    // Cambiar el título dinámicamente
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = 'Tunay Mi acceso de bolsillo'; // Título por defecto
    }

    // Cambiar el favicon dinámicamente
    if (to.meta.favicon) {
        const link = document.querySelector("link[rel~='icon']");
        if (link) {
            link.href = to.meta.favicon;
        } else {
            const newLink = document.createElement('link');
            newLink.rel = 'icon';
            newLink.href = to.meta.favicon;
            document.head.appendChild(newLink);
        }
    }

    next();
});
export default router
