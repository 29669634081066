import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import router from "./routes/index";
import i18n from "@/i18n";
import store from "./store";
import VueExcelXlsx from "vue-excel-xlsx";
import VueMeta from "vue-meta";
import VueClipboard  from "vue-clipboard2";
import browserDetect from "vue-browser-detect-plugin";
import VueSocialSharing from 'vue-social-sharing'
import titleMixin from "@/mixins/titleMixin";
import VueNumber from 'vue-number-animation'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import device from "vue-device-detector"
import IdleVue from 'idle-vue';
import VueMarqueeSlider from 'vue-marquee-slider';
import './assets/styles.css';



window.axios = axios;
axios.defaults.baseURL = process.env.VUE_APP_API;
Vue.config.productionTip = false;
VueClipboard.config.autoSetContainer = true
Vue.mixin(titleMixin)
Vue.use(VueExcelXlsx);
Vue.use(device)

Vue.use(VueMeta);
Vue.use(VueClipboard);
Vue.use(browserDetect);
Vue.use(VueSocialSharing);
Vue.use(VueNumber);
Vue.use(require('vue-script2'))
Vue.use(VueMarqueeSlider)

Vue.use(VueReCaptcha, {
    siteKey: '6LeMXdUUAAAAADqfrL572D4mJ9_j0p0tc-HADIsj',
    loaderOptions: {
        useRecaptchaNet: true
    }
});


const eventsHub = new Vue();

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 10000,
    // startAtIdle: false,
    events: [
        'mousemove',
        'keydown',
        'mousedown',
        'touchstart',
        'scroll',
        'input',
    ],
});


new Vue({
    i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
